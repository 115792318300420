import axios from 'axios';

(function() {

    let ax = axios || {};
    
    window.services = {
        google(data) {
            return ax.post('/api/user/google', data);
        },
        signup(data) {
            return ax.post('/api/user/create', data);
        },
        login(data) {
            return ax.post('/api/user/authenticate', data);
        },
        delete(data) {
            return ax.post('/api/user/delete', data);
        },
        resetPassword(data) {
            return ax.post('/api/user/reset-password', data);
        },
        getPayments() {
            return ax.get('/api/payment/get');
        },
        getSubscriptionPlans() {
            return ax.get('/api/payment/plans');
        },
        submitPayment(data) {
            return ax.post('/api/payment/save', data);
        },
        cancelSubscription() {
            return ax.post('/api/payment/cancel');
        },
        saveAnalytics(data) {
            return ax.post('/api/images/save', data);
        }
    }
})();